import React from 'react';
import Card, { Head, Body } from '../common/Card';
import CardList from '../common/CardList';
import Page from '../common/Page';
import lowConsume from '../../img/low-consume.svg';
import classes from './style.module.scss';

const RiskOfLossPageComponent = () => (
  <div className={classes.riskOfLossPage}>
    <Page title="Risco de perda">
      <CardList>
        <Card>
          <Head icon={lowConsume} hasArrow={false}>Risco de perda por baixo consumo</Head>
          <Body number={5} color="#FD937A">
            Clientes com redução de consumo no
            <b> último mês</b>
          </Body>
        </Card>
        <Card>
          <Body badge="200m²">
            <h4>Imobiliária Verde Mares</h4>
            <ul>
              <li>Último pacote: 100m²</li>
              <li>Previsão de término: 1 semana</li>
            </ul>
          </Body>
        </Card>
        <Card>
          <Body badge="250m²">
            <h4>Gomes Alvarenga Imóveis</h4>
            <ul>
              <li>Último pacote: 90m²</li>
              <li>Previsão de término: 2 semanas</li>
            </ul>
          </Body>
        </Card>
      </CardList>
      <h3>Demais clientes</h3>
      <Card>
        <Body badge="1.500m²">
          <h4>Sul Casa Imóveis</h4>
          <ul>
            <li>Último pacote: 100m²</li>
            <li>Previsão de término: 1 semana</li>
          </ul>
        </Body>
      </Card>
    </Page>
  </div>
);

RiskOfLossPageComponent.propTypes = {};

export default RiskOfLossPageComponent;
