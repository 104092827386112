import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import classes from './style.module.scss';

const CardComponent = ({ children, link }) => {
  if (link) {
    return (
      <Link to={link} className={classes.card}>
        {children}
      </Link>
    );
  }

  return (
    <div className={classes.card}>
      {children}
    </div>
  );
};

CardComponent.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
};

CardComponent.defaultProps = {
  children: null,
  link: null,
};

export default CardComponent;
