import { connect } from 'react-redux';
import { incrementByOne, incrementBy } from '../../store/ducks/counter';
import AppComponent from './AppComponent';

const mapStateToProps = ({ counter }) => ({
  counter,
});

const AppContainer = connect(
  mapStateToProps,
  { incrementByOne, incrementBy }
)(AppComponent);

export default AppContainer;
