import React, { useEffect, useState } from 'react';
import apiInstante from '../services/api-service';
import { toHumanFormat } from '../../constants/units';
import Card, { Head, Body } from '../common/Card';
import CardList from '../common/CardList';
import Page from '../common/Page';
import Spinner from '../common/Spinner';
import potentialPackage from '../../img/potencial-package.svg';
import classes from './style.module.scss';

const PotentialSalesPageComponent = () => {
  const [clients, setClients] = useState([]);
  const [potentialSales, setPotentialSales] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await apiInstante.get('potential-sales');
        setClients(data.clients);
        setPotentialSales(data.potentialSales);
        setIsLoading(false);
      } catch (err) {
        console.log(err.response);
      }
    };

    getData();
  }, []);

  return (
    <div className={classes.potentialSalesPage}>
      <Page title="Potenciais vendas de pacote">
        {isLoading ? <Spinner /> : (
          <CardList>
            <div className="fade-in">
              <Card>
                <Head icon={potentialPackage} hasArrow={false}>Potenciais vendas de pacote</Head>
                <Body number={potentialSales} color="#FDC17A">
                  Clientes com pontencial de
                  <br />
                  venda para a
                  {' '}
                  <b>próxima semana</b>
                </Body>
              </Card>
            </div>
            {clients.map(client => (
              <div className="fade-in" key={client.id}>
                <Card>
                  <Body badge={`${toHumanFormat(client.balance.number, client.balance.unit)}`}>
                    <h4>{client.name}</h4>
                    <ul>
                      <li>
                        {`Consumo da última semana: ${toHumanFormat(
                          client.consumedLastWeek.number,
                          client.consumedLastWeek.unit
                        )}`}
                      </li>
                      <li>
                        {`Previsão de término: ${toHumanFormat(
                          client.timeRemaining.number,
                          client.timeRemaining.unit
                        )}`}
                      </li>
                    </ul>
                  </Body>
                </Card>
              </div>
            ))}
          </CardList>
        )}
      </Page>
    </div>
  );
};

PotentialSalesPageComponent.propTypes = {};

export default PotentialSalesPageComponent;
