import React from 'react';
import PropTypes from 'prop-types';
import classes from './style.module.scss';

const BodyComponent = ({
  badge, children, color, number,
}) => (
  <div className={classes.body}>
    {number !== null ? (
      <div className={classes.number} style={{ backgroundColor: color }}>
        {number}
      </div>
    ) : null}
    <div className={classes.text}>
      {children}
    </div>
    {badge ? (
      <div className={classes.badge}>{badge}</div>
    ) : null}
  </div>
);

BodyComponent.propTypes = {
  badge: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  number: PropTypes.number,
};

BodyComponent.defaultProps = {
  badge: null,
  children: null,
  color: '#bbbbbb',
  number: null,
};

export default BodyComponent;
