import React, { useEffect, useState } from 'react';
import apiInstance from '../services/api-service';
import Card, { Head, Body } from '../common/Card';
import Page from '../common/Page';
import CardList from '../common/CardList';
import Spinner from '../common/Spinner';
import summary from '../../constants/summary';
import classes from './style.module.scss';

const HomePageComponent = () => {
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await apiInstance.get('/summary');
        setCards(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    <div className={classes.homePage}>
      <Page title="Painel gerencial" hasBackButton={false}>
        {isLoading ? <Spinner /> : (
          <CardList>
            {cards.map(card => (
              <div className="fade-in" key={card.type}>
                <Card link={summary[card.type].link}>
                  <Head icon={summary[card.type].icon}>{summary[card.type].title}</Head>
                  <Body number={card.payload.number} color={summary[card.type].color}>
                    {summary[card.type].description}
                  </Body>
                </Card>
              </div>
            ))}
          </CardList>
        )}
      </Page>
    </div>
  );
};

HomePageComponent.propTypes = {};

export default HomePageComponent;
