import React from 'react';
import PropTypes from 'prop-types';
import NavigationBar from './NavigationBar';
import classes from './style.module.scss';

const PageComponent = ({ children, hasBackButton, title }) => (
  <div className={classes.page}>
    <NavigationBar title={title} hasBackButton={hasBackButton} />
    {children}
  </div>
);

PageComponent.propTypes = {
  children: PropTypes.node.isRequired,
  hasBackButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

PageComponent.defaultProps = {
  hasBackButton: true,
};

export default PageComponent;
