// Actions
export const ACTIONS = {
  INCREMENT: 'example/INCREMENT',
  INCREMENT_BY: 'example/INCREMENT_BY',
};

// Reducer
const initialState = 0;
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.INCREMENT:
      return state + 1;
    case ACTIONS.INCREMENT_BY:
      return state + action.payload;
    default:
      return state;
  }
}

// actions
export const incrementByOne = () => ({
  type: ACTIONS.INCREMENT,
});

export const incrementBy = number => ({
  type: ACTIONS.INCREMENT_BY,
  payload: +number,
});
