import axios from 'axios';

import { AUTH_URL, API_URL, REDIRECT_URL } from '../../../config';

if (window.location.hash) {
  const hash = window.location.hash.substring(1);
  if (hash !== '_=_') {
    const parameters = hash.split('&');
    if (parameters.length === 3) {
      if (parameters[0].includes('access_token') && parameters[1].includes('token_type')) {
        const token = parameters[0].substring(parameters[0].indexOf('=') + 1, parameters[0].length);
        const type = parameters[1].substring(parameters[1].indexOf('=') + 1, parameters[1].length);
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('type', type);
        document.location.hash = '_=_';
      }
    }
  }
}

const token = window.localStorage.getItem('token');
const type = window.localStorage.getItem('type') || 'Bearer';

const headers = {};

if (token && type) {
  headers.Authorization = `${type} ${token}`;
}

const instance = axios.create({
  baseURL: API_URL,
  headers,
});

instance.CancelToken = axios.CancelToken;

instance.interceptors.response.use(response => response, (error) => {
  // Do something with response error
  if (error.response && error.response.status === 401) {
    window.location.replace(
      `${AUTH_URL}/login?response_type=token`
      + `&redirect_uri=${REDIRECT_URL}`
      + '&client_id=redevistorias'
      + '&scope=order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*'
    );
  }
  return Promise.reject(error);
});

export default instance;
