import React from 'react';
import PropTypes from 'prop-types';
import leftArrow from '../../../../img/left-arrow.svg';
import classes from './style.module.scss';

const NavigationBarComponent = ({ hasBackButton, title }) => {
  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <div className={classes.navigationBar}>
      {hasBackButton ? (
        <button type="button" onClick={goBack}>
          <img src={leftArrow} alt="Seta para retornar à página anterior" />
        </button>
      ) : null}
      <h1>{title}</h1>
    </div>
  );
};
NavigationBarComponent.propTypes = {
  hasBackButton: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

NavigationBarComponent.defaultProps = {
};

export default NavigationBarComponent;
