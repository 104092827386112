import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import HomePage from '../HomePage';
import NegativeBalancesPage from '../NegativeBalancesPage';
import PotentialSalesPage from '../PotentialSalesPage';
import RiskOfLossPage from '../RiskOfLossPage';
import classes from './style.module.scss';

const AppComponent = () => (
  <div className={classes.app}>
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/negative-balances">
          <NegativeBalancesPage />
        </Route>
        <Route path="/potential-sales">
          <PotentialSalesPage />
        </Route>
        <Route path="/risk-of-loss">
          <RiskOfLossPage />
        </Route>
      </Switch>
    </Router>
  </div>
);

export default AppComponent;
