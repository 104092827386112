import React from 'react';
import potentialPackage from '../img/potencial-package.svg';
import lowConsume from '../img/low-consume.svg';
import soldPackages from '../img/sold-packages.svg';

const summary = {
  'negative-balances': {
    icon: soldPackages,
    title: 'Clientes com saldo negativo',
    color: '#89A6DE',
    description: (
      <>
        Clientes com saldo negativo
        {' '}
        <b>no dia de hoje</b>
      </>
    ),
    link: '/negative-balances',
  },
  'potential-sales': {
    icon: potentialPackage,
    title: 'Potenciais vendas de pacote',
    color: '#FDC17A',
    description: (
      <>
        Clientes com potencial de venda para a
        {' '}
        <b>próxima semana</b>
      </>
    ),
    link: '/potential-sales',
  },
  'risk-of-loss': {
    icon: lowConsume,
    title: 'Risco de perda por baixo consumo',
    color: '#FD937A',
    description: (
      <>
        Clientes com redução de consumo no
        {' '}
        <b>último mês</b>
      </>
    ),
    link: '/risk-of-loss',
  },
  'sales': {
    icon: soldPackages,
    title: 'Pacotes vendidos',
    color: '#89A6DE',
    description: (
      <>
        Vendas na última semana
        <br />
        <b>Total: 25.000 m²</b>
      </>
    ),
    link: '/sales',
  },
};

export default summary;
