import React, { useEffect, useState } from 'react';
import apiInstante from '../services/api-service';
import { toHumanFormat } from '../../constants/units';
import Card, { Head, Body } from '../common/Card';
import CardList from '../common/CardList';
import Page from '../common/Page';
import Spinner from '../common/Spinner';
import potentialPackage from '../../img/potencial-package.svg';
import classes from './style.module.scss';

const NegativeBalancesPageComponent = () => {
  const [clients, setClients] = useState([]);
  const [negativeBalances, setNegativeBalances] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await apiInstante.get('negative-balances');
        setClients(data.clients);
        setNegativeBalances(data.negativeBalances);
        setIsLoading(false);
      } catch (err) {
        console.log(err.response);
      }
    };

    getData();
  }, []);

  return (
    <div className={classes.potentialSalesPage}>
      <Page title="Clientes com saldo negativo">
        {isLoading ? <Spinner /> : (
          <CardList>
            <div className="fade-in">
              <Card>
                <Head icon={potentialPackage} hasArrow={false}>Clientes com saldo negativo</Head>
                <Body number={negativeBalances} color="#FDC17A">
                  Clientes com saldo negativo
                  {' '}
                  <b>no dia de hoje</b>
                </Body>
              </Card>
            </div>
            {clients.map(client => (
              <div className="fade-in" key={client.id}>
                <Card>
                  <Body badge={`${toHumanFormat(client.balance.number, client.balance.unit)}`}>
                    <h4>{client.name}</h4>
                  </Body>
                </Card>
              </div>
            ))}
          </CardList>
        )}
      </Page>
    </div>
  );
};

NegativeBalancesPageComponent.propTypes = {};

export default NegativeBalancesPageComponent;
