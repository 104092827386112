import React from 'react';
import PropTypes from 'prop-types';

import rightArrow from '../../../../img/right-arrow.svg';
import classes from './style.module.scss';

const HeadComponent = ({ children, hasArrow, icon }) => (
  <div className={classes.head}>
    <img src={icon} alt="Ícone" />
    <h2>{children}</h2>
    {hasArrow ? (
      <div className={classes.arrow}>
        <img src={rightArrow} alt="Seta à direita" />
      </div>
    ) : null}
  </div>
);

HeadComponent.propTypes = {
  children: PropTypes.node,
  hasArrow: PropTypes.bool,
  icon: PropTypes.string,
};

HeadComponent.defaultProps = {
  children: null,
  hasArrow: true,
  icon: null,
};

export default HeadComponent;
