const units = {
  week: {
    singular: ' semana',
    plural: ' semanas',
  },
  m2: {
    singular: 'm²',
    plural: 'm²',
  },
};

export function toHumanFormat(number, unit) {
  return `${number}${units[unit][number > 1 ? 'plural' : 'singular']}`;
}

export default units;
