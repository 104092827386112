/* eslint-disable */
export const AUTH_URL = `${window.location.protocol}//${window.location.hostname.replace('dashboard', 'auth')}:${window.location.port}`;
export const BASE_URL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
export const API_URL = `${BASE_URL}/api`;
export const REDIRECT_URL = BASE_URL;

export default {
  API_URL,
  AUTH_URL,
  BASE_URL,
  REDIRECT_URL,
};
