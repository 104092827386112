import React from 'react';
import PropTypes from 'prop-types';

import classes from './style.module.scss';

const CardListComponent = ({ children }) => (
  <div className={classes.cardList}>
    {children}
  </div>
);

CardListComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

CardListComponent.defaultProps = {
};

export default CardListComponent;
